import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import DataTable from 'react-data-table-component';
import { useApi } from 'shared_components/context';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { AdminPersonalInformation } from 'shared_components/generated/admin';
import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WError } from 'shared_components/components/WError/WError';
import AnnotatedEvidenceModal from 'shared_components/components/AnnotatedEvidenceModal';
import { basePath } from 'shared_components/context/auth';
import PageLayout from './PageLayout';
import customFetch from 'shared_components/utils/customFetch';
import { asyncEvidenceUrlIterator } from 'shared_components/utils/asyncEvidenceUrlIterator';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';

interface Row {
  id: string;
  artist: string;
  title: string;
  contribution: string;
  isrcs: string;
  year?: string;
  release: string;
  catNumbers: string;
  label: string;
  format: string;
  mediaType: string;
  isFeatured: boolean;
  isLive: boolean;
  location: string;
  source: string;
  // notes: string;
  annotatedEvidenceObjectKeys: string[];
}

interface GetSignedAnnotatedEvidenceUrlsParams {
  parentRecordingId?: string;
  parentReleaseId?: string;
  maxUrlsPerRelease?: number;
}

type rowIdImageElementMap = {
  [id: string]: HTMLImageElement;
};

type RouteParams = {
  userId: string;
};

function MasterDiscography() {
  const { adminApi: api } = useApi();
  const { throwError } = useWError();

  const [clientPersonalInfo, setClientPersonalInfo] =
    useState<AdminPersonalInformation>();
  const [stageName, setStageName] = useState<string>();
  const [isPendingExportGeneration, setIsPendingExportGeneration] =
    useState<boolean>(false);

  const [rows, setRows] = useState<Row[]>([]);

  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [annotatedEvidenceModelUrls, setAnnotatedEvidenceModelUrls] = useState<
    string[]
  >([]);
  const [isOpenAnnotatedEvidenceModal, setIsOpenAnnotatedEvidenceModal] =
    useState<boolean>(false);
  const [isOpenExportConfirmationModal, setIsOpenExportConfirmationModal] =
    useState<boolean>(false);

  const { userId } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchLatestExportStatus();
    fetchData();
  }, [userId]);

  const fetchData = async () => {
    if (userId === undefined) {
      return;
    }
    setIsLoading(true);
    api
      .retrieveArtistDetails({ userId })
      .then((artistDetails) => {
        setStageName(artistDetails.stageName[0]);
      })
      .catch(() => {});

    api.retrieveAdminPersonalInformation({ userId }).then((personalInfo) => {
      setClientPersonalInfo(personalInfo);
    });

    try {
      const discography = await api.retrievePrimaryDiscography({ userId });

      const rows = discography.recordings.flatMap((recording) => {
        return recording.parentReleases.map((release) => {
          const rowId = `${recording.parentRecording.id}/${release.id}`;
          return {
            id: rowId,
            artist: recording.parentRecording.mainArtists?.toString() ?? '',
            title: recording.parentRecording.recordingTitle[0],
            release: release.releaseTitle?.length
              ? release.releaseTitle[0]
              : '',
            year: release.dateReleased?.at(0),
            isrcs: recording.parentRecording.isrcs?.toString() ?? '',
            contribution: recording.westburyContributions
              .map((westburyContribution) => westburyContribution.contribution)
              .join(','),
            catNumbers:
              recording.parentReleases[0]?.catNumbers?.toString() ?? '',
            label: recording.parentReleases[0]?.releaseLabels?.toString() ?? '',
            format: recording.parentReleases[0]?.format?.toString() ?? '',
            mediaType: recording.parentReleases[0]?.mediaType?.toString() ?? '',
            isFeatured: recording.featured.featuredStatus === 'featured',
            isLive: recording.parentRecording.live?.at(0) ?? false,
            location:
              recording.parentRecording.countryOfRecording?.toString() ?? '',
            source: recording.parentRecording.source.toString(),
            // notes: '',
            annotatedEvidenceObjectKeys: (
              recording.parentRecording.evidenceAnnotatedUrls ?? []
            ).filter(
              (url) =>
                url.includes(release.id) ||
                release.sourceReleaseId.some((id) => url.includes(id))
            ),
          };
        });
      });

      setRows(rows);
    } catch (error) {
      console.error(error);
      throwError(new WError('Failed to load data'));
    }
    setIsLoading(false);
  };

  const fetchLatestExportStatus = async () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveLatestPrimaryDiscographyExport({ userId })
      .then((primaryDiscographyExport) => {
        setIsPendingExportGeneration(!primaryDiscographyExport.ready);
      })
      .catch(() => {});
  };
  useEffect(() => {
    let stream: ReadableStream<Uint8Array>;
    let reader: ReadableStreamDefaultReader<Uint8Array>;
    let intervalId: any;

    const fetchEvidenceUrls = async () => {
      const response = await getSignedAnnotatedEvidenceUrls({
        maxUrlsPerRelease: 1,
      });
      if (response === undefined || !response.body) {
        return;
      }

      stream = response.body;
      reader = stream.getReader();

      // Buffer to hold the images temporarily
      let buffer: { [key: string]: string } = {};

      try {
        const processBatch = () => {
          setImages((prevImages) => ({
            ...prevImages,
            ...buffer,
          }));

          buffer = {};
        };

        // Start the interval to process the batch every 1 second
        intervalId = setInterval(processBatch, 1000);

        for await (const signedUrl of asyncEvidenceUrlIterator(reader)) {
          const { parentRecordingId, parentReleaseId, url } = signedUrl;
          const rowId = `${parentRecordingId}/${parentReleaseId}`;
          buffer[rowId] = url;
        }

        // Process any remaining images
        processBatch();

        clearInterval(intervalId);
      } catch (error) {
        console.error(error);
        throwError(new WError('Failed to load evidence images'));

        clearInterval(intervalId);
      }
    };

    fetchEvidenceUrls();

    return () => {
      if (reader && stream.locked) {
        reader.cancel();
      }
    };
  }, [rows]);

  const getSignedAnnotatedEvidenceUrls = async ({
    parentRecordingId,
    parentReleaseId,
    maxUrlsPerRelease,
  }: GetSignedAnnotatedEvidenceUrlsParams) => {
    if (userId === undefined) {
      return;
    }

    try {
      /**
       * Schema generation does not support streaming responses.
       * As a workaround, this request is performed manually
       * using the Fetch API.
       */
      const response = await customFetch(
        `${basePath}/portal/users/${userId}/primary-discography/annotated-evidence-signed-urls/?` +
          new URLSearchParams({
            ...(parentRecordingId && {
              parent_recording_id: parentRecordingId,
            }),
            ...(parentReleaseId && {
              parent_release_id: parentReleaseId,
            }),
            ...(maxUrlsPerRelease && {
              max_urls_per_release: maxUrlsPerRelease.toString(),
            }),
          })
      );

      return response;
    } catch (error) {
      console.error(error);
      throwError(new WError('Failed to load evidence images'));
    }
  };

  const handleOpenAnnotatedEvidenceModal = async (rowId: string) => {
    const [parentRecordingId, parentReleaseId] = rowId.split('/');
    const response = await getSignedAnnotatedEvidenceUrls({
      parentRecordingId,
      parentReleaseId,
    });
    setIsOpenAnnotatedEvidenceModal(true);
    if (response === undefined || !response.body) {
      return;
    }
    const reader = response.body.getReader();
    try {
      for await (const signedUrl of asyncEvidenceUrlIterator(reader)) {
        setAnnotatedEvidenceModelUrls((prevAnnotatedEvidenceModalUrls) => [
          ...prevAnnotatedEvidenceModalUrls,
          signedUrl.url,
        ]);
      }
    } catch (error) {
      console.error(error);
      throwError(new WError('Failed to load evidence images'));
    }
  };

  const handleCloseAnnotatedEvidenceModal = () => {
    setAnnotatedEvidenceModelUrls([]);
    setIsOpenAnnotatedEvidenceModal(false);
  };

  const handleGenerateExport = () => {
    if (userId === undefined) {
      return;
    }
    api
      .createGeneratePrimaryDiscographyExport({ userId })
      .then(() => {
        setIsOpenExportConfirmationModal(true);
        fetchLatestExportStatus();
      })
      .catch(() => {
        throwError(new WError('Discography export generation request failed'));
      });
  };

  const columns = [
    {
      name: 'Evd',
      cell: (row: Row) => (
        <div className="tw-w-[45px] tw-h-[45px]">
          {row.annotatedEvidenceObjectKeys.length ? (
            <button
              onClick={() => handleOpenAnnotatedEvidenceModal(row.id)}
              className="tw-w-full tw-h-full"
            >
              {images[row.id] ? (
                <img
                  src={images[row.id]}
                  alt="Annotated Evidence"
                  className="tw-w-full tw-h-full"
                />
              ) : (
                <div className="tw-flex tw-justify-center">
                  <p className="tw-my-auto tw-animate-ping">⚪</p>
                </div>
              )}
            </button>
          ) : (
            <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
              <i className="wi wi-shut-eye wi-20px" />
            </div>
          )}
        </div>
      ),
      minWidth: '80px',
      maxWidth: '80px',
    },
    {
      width: '128px',
      name: 'Artist',
      selector: (row: Row) => row.artist,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Recording Title',
      selector: (row: Row) => row.title,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Contrib(s)',
      cell: (row: Row) => (
        <span className="tw-capitalize tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
          {row.contribution}
        </span>
      ),
      selector: (row: Row) => row.contribution,
      sortable: true,
    },
    {
      width: '128px',
      name: 'ISRC(s)',
      selector: (row: Row) => row.isrcs,
      sortable: true,
    },
    {
      width: '80px',
      name: 'Year',
      selector: (row: Row) =>
        row.year ? new Date(row.year).getFullYear() : '',
      sortable: true,
    },
    {
      width: '128px',
      name: 'Release',
      selector: (row: Row) => row.release,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Catalog No',
      selector: (row: Row) => row.catNumbers,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Label',
      selector: (row: Row) => row.label,
      sortable: true,
    },
    {
      width: '100px',
      name: 'Format',
      selector: (row: Row) => row.format,
      sortable: true,
    },
    {
      width: '110px',
      name: 'Type',
      selector: (row: Row) => row.mediaType,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Stage Name',
      selector: () => stageName ?? '',
      sortable: true,
    },
    {
      width: '60px',
      name: 'Ft',
      cell: (row: Row) => (
        <div>
          <i
            className={clsx('wi wi-xs', {
              'wi-check': row.isFeatured,
              'wi-close': !row.isFeatured,
            })}
          />
        </div>
      ),
      selector: (row: Row) => row.isFeatured,
      sortable: true,
    },
    {
      width: '80px',
      name: 'Live',
      cell: (row: Row) => (
        <div>
          <i
            className={clsx('wi wi-xs', {
              'wi-check': row.isLive,
              'wi-close': !row.isLive,
            })}
          />
        </div>
      ),
      selector: (row: Row) => row.isLive,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Country of Recording',
      selector: (row: Row) => row.location,
      sortable: true,
    },
    {
      width: '128px',
      name: 'Source',
      selector: (row: Row) => row.source,
      sortable: true,
    },
    // Phase II
    // {
    //   width: '128px',
    //   name: 'Notes',
    //   selector: (row: Row) => row.notes,
    //   sortable: true,
    // },
  ];

  const customStyles = {
    headRow: {
      style: {
        borderBottom: 'none !important',
        borderBottomColor: 'none !important',
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        background: '#151719',
        color: '#fff',
        paddingBottom: '14px',
        fontWeight: '500',
      },
    },
    cells: {
      style: {
        background: '#151719',
        color: '#fff',
        fontWeight: '300',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        minHeight: '60px',
        borderBottom: '1px solid #535D66 !important',
      },
    },
    pagination: {
      style: {
        color: '#fff',
        fontSize: '13px',

        backgroundColor: '#151719',
      },
      pageButtonsStyle: {
        color: '#fff',
        fill: '#fff',
        backgroundColor: 'transparent',
        '&:hover:not(:disabled)': {
          color: '#fff',
          fill: '#fff',
          backgroundColor: '#151719',
        },
        '&:active': {
          color: '#fff',
          fill: '#fff',
          backgroundColor: '#151719',
        },
        '&:disabled': {
          cursor: 'unset',
          color: '#535D66',
          fill: '#535D66',
        },
      },
    },
  };

  return (
    <PageLayout>
      {isOpenAnnotatedEvidenceModal && (
        <AnnotatedEvidenceModal
          isOpen={isOpenAnnotatedEvidenceModal}
          onClose={handleCloseAnnotatedEvidenceModal}
          urls={annotatedEvidenceModelUrls}
        />
      )}
      <ExportConfirmationModal
        isOpen={isOpenExportConfirmationModal}
        onClose={() => setIsOpenExportConfirmationModal(false)}
      />
      {/* Back Button */}
      <div className="tw-flex tw-mb-6">
        <WButton
          variant="link-secondary"
          icon="chevron-left"
          onClick={() => {
            window.history.back();
          }}
          label="Back"
        />
      </div>
      <div>
        <h2>{`${clientPersonalInfo?.firstName} ${clientPersonalInfo?.lastName}`}</h2>
      </div>
      <div className="tw-grid tw-mt-5 mb-3">
        <div className="tw-justify-self-end">
          <WButton
            variant="secondary"
            label="Export Discography"
            icon="export"
            disabled={isPendingExportGeneration}
            onClick={handleGenerateExport}
          />
        </div>
      </div>
      <WContainer>
        {isLoading ? (
          <div className="tw-flex tw-justify-center">
            <p className="tw-my-auto tw-animate-ping">⚪</p>
          </div>
        ) : (
          <DataTable
            columns={columns}
            // pagination={true} // this needs styling
            data={rows}
            customStyles={customStyles}
            sortIcon={<CustomSortIcon isSorted={false} direction={'asc'} />}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
            paginationPerPage={100}
          />
        )}
      </WContainer>
    </PageLayout>
  );
}
const CustomSortIcon: React.FC<{
  isSorted: boolean;
  direction: 'asc' | 'desc';
}> = ({ isSorted, direction }) => {
  if (!isSorted) {
    return <span> &#8693;</span>; // Not sorted
  }

  return direction === 'asc' ? <span>⇡</span> : <span>⇣</span>;
};

interface ExportConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportConfirmationModal: React.FC<ExportConfirmationModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <WModal isOpen={isOpen} onClose={onClose} title="" hasCloseButton={true}>
      <div className="tw-pb-8">
        <ThankyouBlock
          title="Discography Export In Progress"
          description="Once complete, a download link will appear as a task on your
          dashboard."
        />
      </div>
    </WModal>
  );
};

export default MasterDiscography;
