import React, { useState, useEffect, useMemo } from 'react';
import WContainer from 'shared_components/components/WContainer';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSidebarModal } from 'shared_components/components/WModal/WSidebarModal';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import z from 'zod';
import { PipelineRunStatusStatusEnum } from 'shared_components/generated/admin';
import clsx from 'clsx';

import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WFormFooterSecondary } from 'shared_components/components/WForms/WFormFooterSecondary';
import FullName from 'shared_components/components/FullName';

interface ArtistDiscographyIDCardProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
}

function ArtistDiscographyIDCard({
  isOpen,
  onClose,
  onComplete,
}: ArtistDiscographyIDCardProps) {
  const { adminApi: api } = useApi();

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingIds, setIsEditingIds] = useState(false);

  const hideEmail = function (email: string) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  };
  const { clientId, setClientEmail } = useClientId();
  const [runStatus, setRunStatus] =
    useState<PipelineRunStatusStatusEnum | null>(null);

  type RunStatusMap = {
    [STATUS in PipelineRunStatusStatusEnum]: string;
  };

  const getDiscographyPipelineRunStatus = async () => {
    if (clientId === undefined) {
      return;
    }
    if (!isOpen) {
      return;
    }
    await api
      .retrievePipelineRunStatus({ userId: clientId })
      .then(({ status }) => setRunStatus(status))
      .catch(() => setRunStatus(null));
  };

  const runDiscography = async () => {
    if (clientId === undefined) {
      return;
    }
    setRunStatus(null);
    await api
      .createPipelineRun({ pipelineRunRequest: { userId: clientId } })
      .then(() => getDiscographyPipelineRunStatus())
      .catch(() => {});
  };

  const runStatusMap: RunStatusMap = {
    IN_PROGRESS: 'In progress',
    CACHING: 'Caching data',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
    UNKNOWN: 'Unknown',
  };

  interface FormValue {
    hiddenEmail?: string;
    pplAccountName?: string;
    pplPerformerName?: string;
    discogsId?: string;
    deezerId?: string;
    spotifyId?: string;
    musicbrainzId?: string;
  }
  const [formValue, setFormValue] = useState<FormValue>({});
  const [credentialsFormData, setCredentialsFormData] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
  });

  useEffect(() => {
    setIsEditing(false);
    setIsEditingIds(false);
    fetchData();
  }, [clientId]);

  useEffect(() => {
    getDiscographyPipelineRunStatus();
  }, [isOpen, clientId]);

  const isFormDisabled = useMemo<boolean>(() => {
    if (runStatus === PipelineRunStatusStatusEnum.InProgress) {
      return true;
    }

    if (runStatus === PipelineRunStatusStatusEnum.Caching) {
      return true;
    }

    if (runStatus === PipelineRunStatusStatusEnum.Succeeded) {
      return true;
    }

    return false;
  }, [runStatus]);

  const handleClose = () => {
    if (runStatus === PipelineRunStatusStatusEnum.Succeeded) {
      onComplete && onComplete();
      onClose();
      return;
    }
    onClose();
  };

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api.retrieveUserSettings({ userId: clientId }).then((userSettings) => {
      setCredentialsFormData({
        firstName: userSettings.firstName ?? '',
        lastName: userSettings.lastName ?? '',
        middleName: userSettings.middleName ?? '',
        email: userSettings.emailAddress!,
      });
      setFormValue({
        hiddenEmail: hideEmail(userSettings.emailAddress!),
        pplAccountName: userSettings.pplAccountName ?? '',
        pplPerformerName: userSettings.pplPerformerName ?? '',
        discogsId: userSettings.discogsId ?? '',
        deezerId: userSettings.deezerId ?? '',
        spotifyId: userSettings.spotifyId ?? '',
        musicbrainzId: userSettings.musicbrainzId ?? '',
      });
    });
  };

  const inputChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const inputChangeCredentials = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCredentialsFormData({
      ...credentialsFormData,
      [event.target.name]: event.target.value,
    });
  };
  const blurHandle = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value) {
      setFormValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
    }
  };

  const saveDetails = async () => {
    if (clientId === undefined) {
      return;
    }
    await api
      .partialUpdateUserSettings({
        userId: clientId,
        userSettings: {
          deezerId: formValue.deezerId ?? '',
          discogsId: formValue.discogsId ?? '',
          spotifyId: formValue.spotifyId ?? '',
          musicbrainzId: formValue.musicbrainzId ?? '',
          pplAccountName: formValue.pplAccountName ?? '',
          pplPerformerName: formValue.pplPerformerName ?? '',
          firstName: credentialsFormData.firstName,
          lastName: credentialsFormData.lastName,
          middleName: credentialsFormData.middleName,
          emailAddress: credentialsFormData.email!,
        },
      })
      .then((response) => {
        setCredentialsFormData({
          firstName: response.firstName ?? '',
          lastName: response.lastName ?? '',
          middleName: response.middleName ?? '',
          email: response.emailAddress!,
        });
        setFormValue({
          hiddenEmail: hideEmail(response.emailAddress!),
          pplAccountName: response.pplAccountName ?? '',
          pplPerformerName: response.pplPerformerName ?? '',
          discogsId: response.discogsId ?? '',
          deezerId: response.deezerId ?? '',
          spotifyId: response.spotifyId ?? '',
          musicbrainzId: response.musicbrainzId ?? '',
        });
        if (response.emailAddress) {
          setClientEmail(response.emailAddress);
        }
      })
      .catch((error) => {});
  };

  const schema = z
    .object({
      pplAccountName: z.string(),
      pplPerformerName: z.string(),

      discogsId: z.union([
        z.string().regex(/^\d{1,8}$/, 'Must be 1–8 digit Discogs artist ID'),
        z.string().length(0),
      ]),
      deezerId: z.union([
        z.string().regex(/^\d{1,9}$/, 'Must be 1–9 digit Deezer artist ID'),
        z.string().length(0),
      ]),
      spotifyId: z.string(),
      musicbrainzId: z.union([
        z.string().uuid('Must be MusicBrainz artist UUID'),
        z.string().length(0),
      ]),
    })
    .refine(
      ({ spotifyId }) => {
        if (!spotifyId.length) {
          return true;
        }
        const base62 = /^[0-9A-Za-z_-]{22}$/;
        if (!base62.test(spotifyId)) {
          return false;
        }
        return true;
      },
      {
        path: ['spotifyId'],
        message: 'Must be Spotify artist ID',
      }
    )
    .refine(
      ({ pplPerformerName, discogsId, deezerId, spotifyId, musicbrainzId }) => {
        return (
          pplPerformerName ||
          discogsId ||
          deezerId ||
          spotifyId ||
          musicbrainzId
        );
      },
      {
        path: [
          'pplPerformerName',
          'discogsId',
          'deezerId',
          'spotifyId',
          'musicbrainzId',
        ],
        message: 'At least one field must be filled',
      }
    );

  const artistDetailsSchema = z.object({
    firstName: z.string().min(1),
    middleName: z.string(),
    lastName: z.string().min(1),
    email: z.string().min(1),
  });

  return (
    <WSidebarModal title="" isOpen={isOpen} cardEvent={handleClose}>
      <WContainer>
        <div className="detailsBoxWrap">
          <WFormProvider
            schema={artistDetailsSchema}
            handleSubmit={saveDetails}
            onSuccess={() => setIsEditing(false)}
            formData={credentialsFormData}
          >
            <div className="tw-flex tw-justify-between">
              <span className={`tw-mb-6`}>Artist details</span>

              <FormEditSection setIsEditingPage={setIsEditing} />
            </div>

            <FullName
              editedSettings={{
                firstName: credentialsFormData.firstName,
                lastName: credentialsFormData.lastName,
                middleName: credentialsFormData.middleName,
              }}
              handleInputChange={inputChangeCredentials}
            />

            <WInput
              type="text"
              name="email"
              label="Email"
              onBlur={() => {}}
              onChange={inputChangeCredentials}
              value={credentialsFormData.email}
            />

            <WFormFooterSecondary />
          </WFormProvider>
        </div>
      </WContainer>
      <WContainer>
        <div className="">
          <WFormProvider
            schema={schema}
            handleSubmit={saveDetails}
            onSuccess={() => setIsEditingIds(false)}
            formData={formValue}
          >
            <div className="tw-flex tw-justify-between">
              <span className={`tw-mb-6`}>Discography IDs</span>

              <FormEditSection setIsEditingPage={setIsEditingIds} />
            </div>
            <WInput
              type="text"
              name="pplAccountName"
              label="PPL Account Name"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.pplAccountName}
            />
            <WInput
              type="text"
              name="pplPerformerName"
              label="PPL Performer Name"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.pplPerformerName}
            />
            <WInput
              type="text"
              name="discogsId"
              label="Discogs"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.discogsId}
            />
            <WInput
              type="text"
              name="deezerId"
              label="Deezer"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.deezerId}
            />
            <WInput
              type="text"
              name="spotifyId"
              label="Spotify"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.spotifyId}
            />
            <WInput
              type="text"
              name="musicbrainzId"
              label="MusicBrainz"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.musicbrainzId}
            />
            <WFormFooterSecondary disabled={isFormDisabled} />
          </WFormProvider>
        </div>
      </WContainer>
      <div className="tw-my-6">
        <WButton
          label="Run Discography"
          variant={
            isEditingIds || isEditing || isFormDisabled ? 'dark' : 'primary'
          }
          disabled={isEditingIds || isEditing || isFormDisabled}
          onClick={() => {
            runDiscography();
          }}
        />
      </div>
      {runStatus !== null && (
        <div className="tw-my-4">
          <p className="">
            {runStatusMap[runStatus ?? PipelineRunStatusStatusEnum.Unknown]}
          </p>
        </div>
      )}
    </WSidebarModal>
  );
}

type FormEditSectionProps = {
  setIsEditingPage: (isEditing: boolean) => void;
};

function FormEditSection({ setIsEditingPage }: FormEditSectionProps) {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {' '}
      {!isEditing && (
        <WButton
          label="Edit"
          icon="edit"
          variant="secondary"
          onClick={() => {
            setIsEditing(true);
            setIsEditingPage(true);
          }}
        />
      )}
    </>
  );
}
export default ArtistDiscographyIDCard;
